// import { useEffect, useRef } from "react";
 import { IloginHook } from "../../types/viewtypes";
// import axios from 'config/axios'
// import env from "config/env";
import Swal from "sweetalert2";
import axios from "axios";
// import { temp_data } from "./tempdata";

function addParamsToURL(url: string, params: Object) {
    if (
        params &&
        Object.entries(params).length !== 0 &&
        params.constructor === Object
    ) {
        let temp = url;
        let count = 0;
        for (const [key, value] of Object.entries(params)) {
            temp += `${count === 0 ? '?&' : '&'}${key}=${value}`;
            count++;
        }
        return temp;
    }
    return url;
}

export const useQuickActivationHook = (): IloginHook => {
    const LoginCheck = async (data: any) => {
        // await axios({
        //   method: 'POST',
        //   url: `https://cloudapis.posvega.com/loginauth`,
        //   data: data,
        // }).then(res => {
         
        //   })
        //   .catch(error => {
        //       Swal.fire({
        //         icon: 'error',
        //         title: 'Sorry',
        //         text: "something went wrong",
        //       });
            
        //   });


       
        const body =  data

        const customURL = "https://cloudapis.posvega.com/loginauth";
      
        try {
           
            const response = await axios.post(
                customURL,
                body
            )
            console.log(response);
            
            // setQuickActivationData(response.data)
           
        } catch (e) {
            
            Swal.fire({
                        icon: 'error',
                        title: 'Sorry',
                        text: "something went wrong",
                      });
          
            

        }

      
      };
    return { LoginCheck};
};