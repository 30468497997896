// Chakra imports
import { Flex } from '@chakra-ui/react';
import logo from '../../icons/PVlogo.png'

// Custom components
// import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode
	// let logoColor = useColorModeValue('navy.700', 'white');
	
	return (
		<Flex alignItems='center' flexDirection='column'>
			{/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
			<img  width={"120px"} src={logo} alt="sc"></img>
			<h5><strong>Cloud Back Office</strong></h5>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
